<!--
	A component that conditionally renders content based on the
	value of an A/B experiment for the current user.
	Modeled after FeatureFlag.
 -->
<script context="module">
  import { createEventDispatcher } from 'svelte'
  import { apiExperiments } from '@/api'

  export async function split(name, fallback) {
    try {
      const res = await apiExperiments.evaluate({ experiment_name: name })
      if (res.status === 'OK') {
        return res.data.result
      } else {
        console.warn(
          'experiment lookup returned server-side error',
          name,
          res.data,
        )
        return fallback
      }
    } catch (e) {
      console.error('problem looking up experiment', name, e)
      return fallback
    }
  }
</script>

<script>
  const dispatch = createEventDispatcher()

  export let name
  export let fallback = null
  export let awaitValue = true

  let loaded = false
  let value

  // TODO: Currently this implementation requests experiment groups
  // once, at render. We could leverage websockets (e.g. via Rails ActionCable)
  // to allow the page to re-render immediately at runtime. It's
  // a nice developer experience, but it doesn't actually add all
  // that much in practice.
  $: split(name, fallback).then((v) => {
    value = v
    loaded = true
    dispatch('experiment_split', { experiment: name, value: v })
  })
</script>

{#if !awaitValue || loaded}
  <slot {value} />
{/if}
