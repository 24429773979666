// JsFromRoutes CacheKey 2f061978e3ed8e6a13e72cd04bf99884
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@/util/requests'

export default {
  evaluate: /* #__PURE__ */ definePathHelper(
    'get',
    '/api/experiments/:experiment_name/evaluate',
  ),
}
